<template>
  <v-container fluid>
    <v-sheet class="pa-5">
      <v-row dense>
        <v-col cols="12" md="4">
          <v-card outlined height=250>
            <v-card-title>
              Top SVG Element
            </v-card-title>
            <v-card-text>
              <v-list>
                <pre ref="svg_element">{{ JSON.stringify(svgElement, null, 2) }}</pre>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card outlined height=250>
            <v-card-title>
              Probe Plate Element
            </v-card-title>
            <v-card-text>
              <v-list>
                <pre ref="probe_element">{{ JSON.stringify(probeElement, null, 2) }}</pre>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card outlined height=250>
            <v-card-title>
              DUT Element
            </v-card-title>
            <v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>
<script>
export default {
  name: "ViewControls",
  props: {
    svgElement: {
      type: Object,
      default: null,
    },
    probeElement: {
      type: Object,
      default: null,
    },
    config2d: {
      type: Object,
      default: null,
    },
    xMultiplier: {
      type: Number,
      default: null,
    },
    yMultiplier: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      multiplierList: [
        {
          pk: 0,
          description: "Multiply by 0",
        },
        {
          pk: 1,
          description: "Multiply by 1",
        },
        {
          pk: 2,
          description: "Multiply by 2",
        },
      ],
      xValue: this.xMultiplier,
      yValue: this.yMultiplier,
    };
  },
  computed: {
  },
  methods: {
    updateVB(type) {
      this.$emit("updateVB", { type, value: type == 'x' ? +this.xValue : +this.yValue })
    },
    async copyToClipboard() {
      const text =this.$refs.stackup_result.innerHTML;
      await navigator.clipboard.writeText(text)
      this.$store.commit(
        "ui/SNACK_BAR",
        "Data copied to clipboard."
      );
    },
  },
};
</script>
<style scoped>
  .v-card {
    display: flex !important;
    flex-direction: column;
  }
  .v-card__text {
    flex-grow: 1;
    overflow: auto;
  }
</style>